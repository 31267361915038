import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import ColumnRelationLink from './columnRelationLink'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0011 = (props: Props): JSX.Element => {
  const routePram = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0027/?utm_source=article&utm_medium=post&utm_campaign=Related_article_C0011'
  )
  const routePram2 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0030/?utm_source=article&utm_medium=post&utm_campaign=Related_article_C0011'
  )
  return (
    <Wrapper>
      <ColumnH1 label="就業時間とは？概念や設定方法、賃金計算の仕方について解説" />
      {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
      <p className="p-txt-date">2022.04.15</p>
      <section>
        {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
        <p>
          近年、働き方の見直しを行う動きが活性化しています。そのうえで就業時間や労働時間など、ルールをしっかりと理解することが必須となります。
          <br />
          今回は、就業時間の概念、言葉の違いや計算方法について詳しく解説します。
        </p>
        <img
          src="/images/column/details/c0011.jpg"
          alt="キンクラコラム画像11"
        />
        <h1>労働時間とは</h1>
        <p>
          就業時間について説明をする前に、まずは法律上で基本となる「労働時間」の内容を理解しておきましょう。
          <br />
          <br />
          労働時間とは、会社と雇用契約を交わした社員が、
          <span>会社の指示を受けて働いている時間</span>のことです。
          <br />
          具体的には、実際に仕事である作業や電話対応、会議などを行う時間に加え、現場間を移動する時間、電話番の時間、作業前の待機時間、制服や作業着に着替える時間など、
          <span>会社の指揮命令下で動いている時間すべて</span>
          が労働時間として扱われます。また、強制参加となる教育訓練や特殊健康診断を受ける時間帯などについても、会社の指示による行動とみなされるため、労働時間になります。
          <br />
          <br />
          労働時間には、<span>法定労働時間</span>と<span>所定労働時間</span>
          の2種類があります。そのうち法定労働時間とは、法律によって「これ以上働かせてはいけない」と決められている上限時間のことで、休憩時間を除き1日あたり8時間、1週間あたり40時間が原則になります。
          <br />
          <br />
          一方、所定労働時間とは、会社ごとに就業規則や雇用契約書などでルール化された、実際に会社で就労する時間をいいます。たとえば、9：00～18：00までの間に12：00～13：00の1時間を休憩時間として設定している会社の場合、就業時間は9：00～18：00までの9時間から休憩時間1時間を控除した「8時間」が所定労働時間となります。
        </p>
        <h1>「就業時間」とは何か</h1>
        <p>
          就業時間は、前述の所定労働時間に休憩時間も含めた、
          <span>「業務の開始時間から修了時間までの通算した時間」</span>
          のことです。
          <br />
          つまり、先ほど例に挙げた、9：00～18：00までの間に12：00～13：00の1時間を休憩時間として設定している会社の場合は、就業時間は9：00～18：00までの9時間になります。
          <br />
          なお、似たような言葉である「就労時間」や「勤務時間」についても、就業時間と同じものだといっても過言ではありません。
        </p>

        {/** ここに9パターンの広告設定する */}
        {props.no === 0 && <Introduction004 />}
        {props.no === 1 && <Introduction005 />}
        {props.no === 2 && <Introduction006 />}
        {props.no === 3 && <Introduction007 />}
        {props.no === 4 && <Introduction008 />}
        {props.no === 5 && <Introduction009 />}
        {props.no === 6 && <Introduction010 />}
        {props.no === 7 && <Introduction011 />}
        {props.no === 8 && <Introduction012 />}

        <h1>就業時間の設定法</h1>
        <p>
          就業時間は、所定労働時間に休憩時間を加えたものとなるため、法定労働時間内の範囲であれば、会社の状況に応じて自由に時間帯を設定することができます。極端な話、17：00～24：00など、夜間に就業時間を設定することが可能です。
          <br />
          また、休憩時間については、
          <span>
            6時間を超える就業時間を定める場合は45分以上、8時間を超える就業時間を定める場合は1時間以上
          </span>
          を与えなければならないと定められています。管理監督者である社員については休憩付与義務の対象外ですが、管理監督者の肩書きがありながら一般社員と同じ働き方をしている、いわゆる「みなし管理職」については一定の休憩時間を与えなければならない点に注意が必要です。
        </p>
        <h1>就業時間と残業代の関係</h1>
        <p>
          ここからは、実際の例を確認しながら、気になる残業代の有無について確認をしていきましょう。
          <br />
          たとえば、就業時間が9：00～17：00で、そのうち12：00～13：00の時間帯を休憩時間としている会社があるとします。この会社の場合、就業時間が9：00～17：00の8時間、休憩時間が1時間であるため、法定労働時間を遵守していることになります。
          <br />
          つまり、就業時間から休憩時間を差し引いた7時間が所定労働時間となるため、9時から17時まで働いた場合は残業代の発生はありません。
          <br />
          <br />
          もしも、17時を超えて1時間の残業をした場合は、トータルの労働時間は「8時間」で法定労働時間内の労働となるため、所定内残業扱いになり、1時間労働分の賃金を支払えば足ります。割増賃金の支払いは必要ありません。
          <br />
          ただし、17時から1時間を超えて残業をした場合は、法定労働時間を超えた労働となるため、法定労働時間を超えた時間帯に沿って
          <span>割増賃金の支払い</span>が必要となります。
          <br />
          <br />
          賃金に上乗せされる割増賃金率は、以下の通り定められています。
          <br />
          休日、深夜など労働者へかかる負担が大きいと判断される場合は、割増賃金率が高く設定されている点に注意が必要です。また、月60時間超の長時間にわたる労働者向けの割増率アップに関するルールは、現時点では大企業のみが適用となり、
          <span>中小企業の適用開始時期は令和5年4月以降</span>とされています。
          <br />
          <br />
          ・月60時間以下の時間外労働の場合：25％以上
          <br />
          ・月60時間超の時間外労働の場合：50％以上（※大企業のみ）
          <br />
          ・休日労働の場合：35％以上
          <br />
          ・深夜時間帯（22：00～翌5：00）の労働の場合：25％以上
          <br />
          <br />
          なお、時間外労働が深夜の時間帯（深夜業）に重なった場合は、割増賃金率は次の通り加算されます。
          <br />
          <br />
          ・月60時間以下の時間外労働＋深夜業の場合：50％以上
          <br />
          ・月60時間超の時間外労働＋深夜業の場合：75％以上（※大企業のみ）
          <br />
          ・休日労働＋深夜業の場合：60％以上
        </p>
        <ColumnRelationLink
          link={routePram}
          label="残業とは何か？残業の定義や上限時間などを解説します"
        />
        <ColumnRelationLink
          link={routePram2}
          label="試用期間中に残業することに！残業代は支払われますか？"
        />
        <h1 className="read">まとめ</h1>
        <p>
          就業時間の概念や計算方法について、お分かりいただけたかと思います。賃金計算ミスや不要な労使トラブルを防ぐためにも、まずは会社内の所定労働時間を再度見直してみてはいかがでしょうか。
          <br />
          その上で、仕事の準備時間や着替え時間を労働時間から外していないか、昼休憩中に電話番をさせていないか、などのあいまいな時間帯を洗い出し、正しい方法で賃金を計算することを心がけましょう。なお、法定労働時間を超えた労働をさせる場合は、
          <span>法律により36協定の届け出が義務</span>
          づけられていますので、届け出漏れがないか気をつける必要があります。
        </p>
      </section>
    </Wrapper>
  )
}

export default ColumnDetailInner0011
